import Btn from '@/component/button/btn/btn';
import Btn_action from '@/component/button/btn_action/btn_action';
import Icon_load from '@/component/icon/icon_load/icon_load';
import Icon_loading from '@/component/icon/icon_loading/icon_loading';
import Tip_text from '@/component/tip/tip_text/tip_text';
import { T_page } from '@/declaration/rds/model/type/t_page';
import { loading } from '@/utility/dynamic/loading';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { UsePaginationProps } from '@mui/material/usePagination/usePagination';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, { DOMAttributes, FC, memo, ReactNode } from 'react';

const Pagination = dynamic(() => import('@mui/material/Pagination'), { loading });

export interface I_Lister_action_bar extends DOMAttributes<HTMLDivElement> {
  /**
   * Use numbered pagination
   */
  pagination?: boolean;

  /**
   * Total count
   */
  count?: number;
  /**
   * Loaded list length
   */
  count_loaded?: number;

  on_change_pagination?: UsePaginationProps['onChange'];

  page?: T_page;

  /**
   * Pagination properties to merge
   */
  i_pagination?: Partial<UsePaginationProps>;

  pending?: boolean;

  hide_indicator?: boolean;

  class_indicator?: string;

  on_reload?: () => void;

  on_next_page?: () => void;

  no_more?: boolean;

  error?: boolean | Error;

  indicator_loading?: ReactNode;
}

const Lister_action_bar: FC<I_Lister_action_bar> = memo<I_Lister_action_bar>(
  ({
    pagination,
    count,
    count_loaded,
    on_change_pagination,
    page,
    i_pagination,
    pending,
    hide_indicator,
    class_indicator = '',
    on_reload,
    no_more,
    on_next_page,
    error,
    indicator_loading,
  }: I_Lister_action_bar) => {
    const { t } = useTranslation();

    if (!count && !count_loaded) {
      return null;
    }

    if (pagination) {
      return (
        <div>
          {!!count_loaded && (
            <div className="flex justify-center py-1">
              <Pagination
                onChange={on_change_pagination}
                page={(page?.index ?? 0) + 1}
                count={page?.size ? Math.ceil((count || 0) / page.size) : 1}
                size="small"
                shape="rounded"
                showFirstButton
                showLastButton
                {...i_pagination}
              />
            </div>
          )}
        </div>
      );
    } else {
      if (pending) {
        return (
          <div>
            {!hide_indicator && (
              <div className={'h-[1.2em] ' + class_indicator}>{indicator_loading || <Icon_loading />}</div>
            )}
          </div>
        );
      } else {
        if (error) {
          return (
            <div>
              <div className={'h-[1.2em] ' + class_indicator}>
                <Tip_text title={t('common:reload')}>
                  <Btn_action onClick={on_reload}>
                    <Icon_load />
                  </Btn_action>
                </Tip_text>
              </div>
            </div>
          );
        } else {
          if (no_more) {
            return (
              <div>
                {!hide_indicator && !!count && (
                  <div className={'text-muted h-[1.2em] text-xs capitalize ' + class_indicator}>
                    🤷‍♀️ {t('common:no_more')} 🤷‍♂
                  </div>
                )}
              </div>
            );
          } else {
            if (!pending && !scroll) {
              return (
                <div>
                  <Btn onClick={on_next_page} variant="text" full_width>
                    <MoreHorizIcon />
                  </Btn>
                </div>
              );
            }
          }
        }
      }
    }
  },
);

export default Lister_action_bar;
