import { I_Lister } from '@/component/lister/lister';
import React, { createContext, FC, HTMLAttributes, memo, ReactNode, useMemo } from 'react';
import { Subject } from 'rxjs';

export enum N_list_event {
  append = 'append',
  prepend = 'prepend',
  cram = 'cram',
  merge = 'merge',
  replace = 'replace',
  remove = 'remove',
  move = 'move',
  move_by_key = 'move_by_key',
  children_count = 'children_count',
  update = 'update',
}

type T_key = number | string;

export interface T_cram_row<T> {
  index: number;
  row: T;
}

export type T_lister_event<T = any> =
  | { type: N_list_event.update; data: T[] }
  | { type: N_list_event.prepend; data: T[] }
  | { type: N_list_event.append; data: T[] }
  | { type: N_list_event.merge; data: T[] }
  | { type: N_list_event.replace; data: T[] }
  | { type: N_list_event.cram; data: T_cram_row<T>[] }
  | { type: N_list_event.remove; data: T_key[] }
  | { type: N_list_event.move; data: { from: number; to: number; pid?: I_Lister['pid'] } }
  | { type: N_list_event.move_by_key; data: { key: T_key; to: number } }
  | { type: N_list_event.children_count; data: { key: T_key; count: number } };

export interface T_lister_context<T = any> {
  bus?: Subject<T_lister_event<T>>;
}

export const Context_list = createContext<T_lister_context>({});

export interface I_Tree_context extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const Lister_context: FC<I_Tree_context> = memo<I_Tree_context>((props: I_Tree_context) => {
  const { children } = props;
  const value = useMemo<T_lister_context>(
    () => ({
      bus: new Subject<T_lister_event>(),
    }),
    [],
  );

  return <Context_list.Provider value={value}>{children}</Context_list.Provider>;
});

export default Lister_context;
