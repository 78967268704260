import { I_base_read } from '@/declaration/api/type/i_base_read';
import { Base } from '@/declaration/rds/model';
import { I_page } from '@/declaration/rds/model/type/i_page';
import { T_model_list } from '@/declaration/rds/model/type/t_model_list';
import { T_page } from '@/declaration/rds/model/type/t_page';
import { $api } from '@/service/$api';
import { list_upsert } from '@/utility/array/list_upsert';
import { loading } from '@/utility/dynamic/loading';
import { arrayMoveImmutable } from 'array-move';
import { orderBy } from 'lodash';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import set from 'lodash/set';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, {
  CSSProperties,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebounce, usePrevious, useToggle } from 'react-use';
import scrollIntoView, { StandardBehaviorOptions } from 'scroll-into-view-if-needed';
import scroll_parent from 'scrollparent';
import { Required } from 'utility-types';
import { I_List, T_list_context, T_list_key } from './list/list';
import Lister_action_bar, { I_Lister_action_bar } from './lister_action_bar/lister_action_bar';
import { Context_list, N_list_event, T_cram_row, T_lister_event } from './lister_context';
import { I_Table } from './table/table';

const List = dynamic(() => import('./list/list'), { loading });
const Table = dynamic(() => import('./table/table'), { loading });

export enum N_lister {
  list = 'list',
  table = 'table',
}

export interface T_lister_context extends T_list_context {}

export interface T_args extends I_page {}

export interface T_args_page_ensure extends Required<I_page, 'page'> {
  page: Required<T_page>;
}

export interface I_Lister<Row extends Base = any, Args extends T_args = I_base_read<Row>>
  extends Pick<I_List, 'prop_key' | 'class_wrapper' | 'class_item' | 'i_wrapper' | 'i_item'>,
    Pick<
      I_Table,
      | 'class_table'
      | 'class_thead'
      | 'class_tbody'
      | 'class_tr'
      | 'class_cell'
      | 'class_th'
      | 'class_td'
      | 'no_divider_row'
      | 'no_hover_feedback'
    > {
  type?: N_lister;
  api?: string;
  count?: number;
  list?: Row[];
  render_row?: (item: Row, index: number, context: T_lister_context) => ReactNode;
  render_header?: (context?: any) => ReactNode;
  render_footer?: (context?: any) => ReactNode;
  args?: Args;
  empty_holder?: ReactNode;
  default_height?: number;
  no_scroll_load?: boolean;
  hide_indicator_start?: boolean;
  hide_indicator_end?: boolean;
  keyword?: string;
  /**
   * Parent id
   */
  pid?: null | number | string;
  /**
   * Parent property name
   */
  prop_pid?: keyof Row;
  searchable?: (keyof Row)[]; // searchable fields
  search_placeholder?: string;
  search_auto_focus?: boolean;
  hide_search?: boolean;
  modifier_keyword?: (keyword?: string) => string | undefined;
  parser_keyword?: (keyword: string | undefined, orignal_args: Args) => Args;
  /**
   * list property path
   */
  path_list?: string;
  /**
   * count property path
   */
  path_count?: string;
  /**
   * Reload count
   */
  reload?: number;
  /**
   * Append new items ()
   */
  prepend?: boolean;
  /**
   * merge new items on same keys (replace by default)
   */
  merge?: boolean;

  map?: (list: Row[]) => Row[];

  i_table?: Partial<I_Table<Row>>;

  max_height?: CSSProperties['height'];

  /**
   * Max height of main content (without title or footer, like tbody or list)
   */
  max_height_main?: CSSProperties['maxHeight'];

  /**
   * Rows that will not be affected by pagination
   */
  rows_static?: Row[];

  /**
   * Rows to merge
   */
  rows_merge?: Row[] | Row;

  /**
   * Rows to delete (by key)
   */
  rows_remove?: T_list_key[] | T_list_key;

  /**
   * Use numbered pagination
   */
  pagination?: I_Lister_action_bar['pagination'];

  /**
   * Pagination properties to merge
   */
  i_pagination?: I_Lister_action_bar['i_pagination'];

  /**
   * Sort by property
   */
  prop_sort?: T_list_key;
  /**
   * Sort direction, default: desc
   */
  prop_sort_asc?: boolean;

  /**
   * Reverse page order
   */
  reverse?: boolean;

  sort?: (a: Row, b: Row) => number;

  filter?: (list: Row[]) => Row[];

  /**
   * Scroll to and focus (key value)
   */
  scroll_to?: T_list_key;
  i_scroll?: Partial<StandardBehaviorOptions>;
  indicator_loading?: ReactNode;
  on_list_change?: (list: Row[]) => void;
  on_count_change?: (num: number) => void;
  on_keyword_change?: (keyword?: string) => void;
  on_show_start?: (el: Element | null) => void;
  on_show_end?: (el: Element | null) => void;
  on_hide_start?: (el: Element | null) => void;
  on_hide_end?: (el: Element | null) => void;
  on_change_args?: (args: Args) => void;
  on_pending_change?: (pending: boolean) => void;
  pid_sensitive?: boolean;
}

const args_def: T_args = {
  page: {
    index: 0,
    size: 25,
  },
};

const class_indicator = 'h-[1.2em] text-center my-1';

function normalize_a<Args>(args?: T_args): Args & T_args_page_ensure {
  return _merge({}, args_def, args) as any;
}

const Lister = <Row extends object = any, Args extends T_args = I_base_read>(
  props: I_Lister<Row, Args>,
): ReactElement<I_Lister> => {
  const {
    path_list = 'list',
    path_count = 'count',
    type = N_lister.list,
    prop_key = 'id' as T_list_key,
    list,
    api,
    args,
    hide_indicator_start,
    hide_indicator_end,
    render_header,
    render_footer,
    reload,
    no_scroll_load,
    merge,
    map,
    i_table,
    keyword,
    hide_search,
    searchable,
    search_placeholder,
    search_auto_focus,
    modifier_keyword,
    parser_keyword,
    max_height,
    max_height_main,
    rows_merge,
    rows_remove,
    rows_static,
    pagination = false,
    i_pagination,
    prop_sort = prop_key,
    prop_sort_asc,
    sort,
    reverse,
    filter,
    scroll_to,
    indicator_loading,
    on_count_change,
    on_keyword_change,
    on_list_change,
    on_show_start,
    on_show_end,
    on_change_args,
    on_pending_change,
    i_scroll,
    pid_sensitive,
    count,
  } = props;
  let { pid, prop_pid } = props;
  if (!list && !api) {
    throw new Error('required {list | api}');
  }
  const { t } = useTranslation();
  prop_pid = prop_pid || ('pid' as any);
  const empty_holder = useMemo(
    () =>
      props.empty_holder ?? (
        <div className="px-2 text-foreground/40 h-[1.2em] text-xs capitalize">{t('common:empty')}</div>
      ),
    [props.empty_holder, t],
  );
  const args_prev = usePrevious(args);
  const [a, set_a] = useState<Args & T_args_page_ensure>(normalize_a(args));
  const [_count, set__count] = useState<number | undefined>(count);
  const [p_page, set_p_page] = useToggle(false); // pending load page
  const [dirty, set_dirty] = useState<number>(0);
  const a_prev = usePrevious(a);
  const [ls, set_ls] = useState<Row[]>(list || []);
  const ls_prev = usePrevious(ls);
  const [page_error, set_page_error] = useState<any>(null);
  const [_keyword, set__keyword] = useState<string | undefined>(keyword);
  const [de_keyword, set_de_keyword] = useState<string | undefined>(_keyword);
  const de_keyword_prev = usePrevious(de_keyword);
  const dirty_prev = usePrevious(dirty);
  const reload_prev = usePrevious(reload);
  const api_prev = usePrevious(api);
  const count_prev = usePrevious(_count);
  const rows_merge_prev = usePrevious(rows_merge);
  const rows_remove_prev = usePrevious(rows_remove);
  const { bus } = useContext(Context_list);
  const el_scroller_y = useRef<HTMLElement | null>(null);
  const el_content = useRef<HTMLDivElement | null>(null);
  const [scroll_y, set_scroll_y] = useState<number>(0);
  const [list_loaded, list_loaded_toggle] = useToggle(!list?.length);
  const opt_scroll = useMemo<StandardBehaviorOptions>(
    () =>
      _merge(
        {},
        {
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        },
        i_scroll,
      ),
    [i_scroll],
  );
  const [last_scroll_id, set_last_scroll_id] = useState<T_list_key>();

  const [page_min, page_max] = useMemo(
    () => [0, _count ? Math.ceil(_count / (a?.page?.size ?? 1)) : 0],
    [a?.page?.size, _count],
  );
  const [loaded_page_min, set_page_min_loaded] = useState<number>();
  const [loaded_page_max, set_page_max_loaded] = useState<number>();
  const fully_loaded = useMemo(
    () => ls.length - (rows_static?.length ?? 0) >= (_count ?? 0),
    [_count, ls.length, rows_static?.length],
  );
  const no_more_asc = useMemo(
    () => fully_loaded || (loaded_page_max === undefined ? false : loaded_page_max >= page_max),
    [fully_loaded, loaded_page_max, page_max],
  );
  const no_more_desc = useMemo(
    () => fully_loaded || (loaded_page_min === undefined ? false : loaded_page_min <= page_min),
    [fully_loaded, loaded_page_min, page_min],
  );
  const _pagination = useMemo(
    () => _merge({}, { page: a?.page?.index + 1 }, i_pagination),
    [a?.page?.index, i_pagination],
  );
  const is_empty = !p_page && (api ? !_count && !ls?.length : !ls?.length);

  useDebounce(() => set_de_keyword(_keyword), 300, [_keyword]);

  const _on_keyword_change = useCallback(
    (e: any) => {
      let value = e.target.value;
      value = modifier_keyword ? modifier_keyword(value) : value;
      set__keyword(value);
    },
    [modifier_keyword],
  );

  const _filter = useCallback(
    (list: Row[], opt?: I_filter) => {
      let r = list;

      if (filter) {
        r = filter(r);
      }

      if (opt?.pid_sensitive) {
        r = r.filter((it) => pid == (it as any)?.[prop_pid]);
      }

      return r;
    },
    [filter, pid, prop_pid],
  );

  const _sort = useCallback(
    (list: Row[]) => {
      let r = list;
      if (prop_sort) {
        r = orderBy(r, prop_sort, prop_sort_asc ? 'asc' : 'desc') as any;
      }

      if (sort) {
        r = r.sort(sort);
      }

      return r;
    },
    [prop_sort, prop_sort_asc, sort],
  );

  const set_ls_safe = useCallback(
    (list: Row[], opt?: I_set_ls) => {
      if (list?.length) {
        set_ls(_sort(_filter(list, opt)));
      } else {
        set_ls([]);
      }
    },
    [_filter, _sort],
  );

  const set_ls_keep_static = useCallback(
    (list: Row[], opt?: I_set_ls) => {
      if (rows_static?.length) {
        set_ls_safe([...rows_static, ...list], opt);
      } else {
        set_ls_safe(list, opt);
      }
    },
    [rows_static, set_ls_safe],
  );

  const ls_upsert = useCallback(
    (rows: Row[], opt?: I_set_ls): void => {
      if (!rows.length) {
        return;
      }

      let r = list_upsert(ls, rows, prop_key as any, { prepend: reverse, merge });
      set_ls_safe([...r], opt);
    },
    [ls, prop_key, reverse, merge, set_ls_safe],
  );

  const ls_append = useCallback(
    (rows: Row[], opt?: I_set_ls) => {
      set_ls_safe([...ls, ...rows], opt);
    },
    [ls, set_ls_safe],
  );

  const ls_prepend = useCallback(
    (rows: Row[], opt?: I_set_ls) => {
      set_ls_safe([...rows, ...ls], opt);
    },
    [ls, set_ls_safe],
  );

  const ls_cram = useCallback(
    (input: T_cram_row<Row>[], opt?: I_set_ls) => {
      const r: Row[] = [];
      for (const crow of input) {
        for (const [i, row] of ls.entries() as any) {
          if (i === crow.index) {
            r.push(crow.row);
          }
          if (prop_sort) {
            if (i >= crow.index) {
              row[prop_sort] = row[prop_sort] + 1;
            }
          }
          r.push({ ...row });
        }
      }

      set_ls_safe(r, opt);
    },
    [ls, prop_sort, set_ls_safe],
  );

  const ls_move = useCallback(
    (from: number, to: number, _pid?: I_Lister['pid']) => {
      if (_pid !== pid) {
        return;
      }
      let r = arrayMoveImmutable(ls, from, to);
      if (prop_sort) {
        // todo: not accurate enough, should based on original `prop_sort` value
        r = r.map((it, i) => ({ ...it, i }));
      }
      set_ls_safe(r);
    },
    [ls, pid, prop_sort, set_ls_safe],
  );

  const ls_move_by_key = useCallback(
    (key: T_list_key, to: number) => {
      const from = ls.findIndex((it: any) => it[prop_key] === key);
      if (from === -1) {
        return;
      }
      ls_move(from, to);
    },
    [ls, ls_move, prop_key],
  );

  const ls_remove = useCallback(
    (keys: T_list_key[]) => {
      if (!keys.length) {
        return;
      }
      set_ls(ls.filter((it: Row) => !keys.includes(it[prop_key as keyof Row] as any)));
    },
    [prop_key, ls],
  );

  const ls_reset = useCallback(
    (list?: Row[], opt?: I_set_ls): void => {
      list = list ?? [];
      if (rows_static?.length) {
        set_ls_safe([...rows_static, ...list], opt);
      } else {
        set_ls_safe(list, opt);
      }
    },
    [rows_static, set_ls_safe],
  );

  const tap_dirty = useCallback((): void => {
    set_dirty(dirty + 1);
  }, [dirty]);

  const page_next = useCallback(
    (desc = false): void => {
      if (p_page) {
        return;
      }

      if (desc && no_more_desc) {
        return;
      }

      if (!desc && no_more_asc) {
        return;
      }

      const copy = { ...a } as T_args_page_ensure;
      set(copy, 'page.index', copy.page.index + 1);
      set_a(copy as any);
    },
    [a, no_more_asc, no_more_desc, p_page],
  );

  const reset = useCallback(() => {
    ls_reset();
    set_a(args_def as any);
  }, [ls_reset]);

  const list_reload = useCallback(() => {
    ls_reset();
    tap_dirty();
  }, [ls_reset, tap_dirty]);

  const style_scroll = useMemo(
    () =>
      max_height
        ? {
            maxHeight: max_height,
            overflow: 'auto',
          }
        : undefined,
    [max_height],
  );

  const handle_pagination_change = useCallback(
    (e: any, page: number) => set_a(_merge({}, a, { page: { index: page - 1 } })),
    [a],
  );

  const load_a_by_keyword = useCallback(
    (kwd?: string) => {
      let or_where_contains = {} as any;
      if (!searchable?.length) {
        return;
      }
      if (kwd) {
        for (const it of searchable) {
          or_where_contains[it] = de_keyword;
        }
        set_a(_merge({}, a, { or_where_contains, page: { index: 0 } }));
      } else {
        const copy = { ...a } as I_base_read;
        copy.or_where_contains = undefined;
        set_a(copy as any);
      }
    },
    [a, de_keyword, searchable],
  );

  const action_bar_render = useCallback(
    (head = false /* true: as starting bar, false: as ending bar */) => {
      return (
        <Lister_action_bar
          page={a?.page}
          pagination={pagination}
          i_pagination={_pagination}
          on_change_pagination={handle_pagination_change}
          count={_count}
          count_loaded={ls.length}
          on_reload={tap_dirty}
          on_next_page={() => page_next(head)}
          pending={p_page}
          hide_indicator={head ? hide_indicator_start : hide_indicator_end}
          class_indicator={class_indicator}
          no_more={head ? no_more_desc : no_more_asc}
          error={page_error}
          indicator_loading={indicator_loading}
        />
      );
    },
    [
      a?.page,
      pagination,
      _pagination,
      handle_pagination_change,
      _count,
      ls.length,
      tap_dirty,
      p_page,
      hide_indicator_start,
      hide_indicator_end,
      no_more_desc,
      no_more_asc,
      page_error,
      indicator_loading,
      page_next,
    ],
  );

  const scroll_to_id = useCallback(
    (id: T_list_key) => {
      const el = el_content.current?.querySelector(`[data-key="${id as string}"]`);
      if (!el) {
        return;
      }

      scrollIntoView(el, opt_scroll);
      set_last_scroll_id(id);
    },
    [opt_scroll],
  );

  const _on_show_start = useCallback(
    (el: any) => {
      on_show_start && on_show_start(el);
    },
    [on_show_start],
  );

  const _on_show_end = useCallback(
    (el: any) => {
      if (!no_scroll_load) {
        page_next();
      }
      on_show_end && on_show_end(el);
    },
    [no_scroll_load, on_show_end, page_next],
  );

  const render_list = useCallback(() => {
    let r;
    if (is_empty) {
      return empty_holder;
    }
    switch (type) {
      case N_lister.list:
        r = (
          <List
            {...(props as any)}
            max_height_main={max_height_main}
            on_show_start={_on_show_start}
            on_show_end={_on_show_end}
            source={ls}
            reverse={reverse}
          />
        );
        break;
      case N_lister.table:
        r = (
          <Table
            {...(i_table as any)}
            max_height_main={max_height_main}
            on_show_start={_on_show_start}
            on_show_end={_on_show_end}
            source={ls}
            reverse={reverse}
          />
        );
        break;
      default:
        r = `Invalid lister type: "${type}"`;
        break;
    }
    return r;
  }, [_on_show_end, _on_show_start, empty_holder, i_table, is_empty, ls, max_height_main, props, reverse, type]);

  useEffect(() => {
    if (!isEqual(ls, ls_prev)) {
      on_list_change && on_list_change(ls);
    }
  }, [ls, ls_prev, on_list_change]);

  useEffect(() => {
    if (isEqual(args, args_prev)) {
      return;
    }
    reset();
    set_a(normalize_a(args));
  }, [args, args_prev, reset]);

  useEffect(() => {
    if (reload && reload !== reload_prev) {
      list_reload();
    }
  }, [list_reload, reload, reload_prev]);

  useEffect(() => {
    // let dead = false;

    void load();

    async function load(): Promise<void> {
      if (list?.length) {
        if (!isEqual(list, ls)) {
          ls_reset(list);
        }

        set__count(count ?? list?.length);
        list_loaded_toggle(true);
      }

      if (list_loaded) {
        if (!api) {
          console.warn('both {list} and {api} are empty');
          return;
        }

        if (p_page || dirty === dirty_prev) {
          return;
        }
        set_page_error(false);
        try {
          set_p_page(true);
          // todo: only works for bottom append now
          set_scroll_y(el_scroller_y.current?.scrollTop ?? 0);
          const r: any = await read(api, a);
          // if (dead) { return; }
          let list = get(r, path_list);
          const c = get(r, path_count);
          set__count(c);
          list = map ? map(list) : list;
          if (pagination) {
            set_ls_keep_static(list, { pid_sensitive });
          } else {
            ls_upsert(list, { pid_sensitive });
          }
          const pi = a.page.index;
          if (loaded_page_max === undefined || pi > loaded_page_max) {
            set_page_max_loaded(pi);
          }

          if (loaded_page_min === undefined || pi < loaded_page_min) {
            set_page_min_loaded(pi);
          }
        } catch (e) {
          set_page_error(e);
        }
        set_p_page(false);
      }
    }

    async function read(api: string, args?: Args): Promise<T_model_list> {
      return $api.call(api, args);
    }

    // return () => {
    //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //   dead = true;
    // };
  }, [
    a,
    api,
    count,
    dirty,
    dirty_prev,
    list,
    list_loaded,
    list_loaded_toggle,
    loaded_page_max,
    loaded_page_min,
    ls,
    ls_reset,
    ls_upsert,
    map,
    p_page,
    pagination,
    path_count,
    path_list,
    pid_sensitive,
    set_ls_keep_static,
    set_p_page,
  ]);

  useEffect(() => {
    if (!Object.is(a, a_prev)) {
      on_change_args && on_change_args(a);
      tap_dirty();
    }
  }, [a, a_prev, on_change_args, tap_dirty]);

  useEffect(() => {
    if (api !== api_prev) {
      list_reload();
    }
  }, [api, api_prev, list_reload]);

  useEffect(() => {
    if (count_prev !== _count) {
      on_count_change && on_count_change(_count || 0);
    }
  }, [_count, count_prev, on_count_change]);

  useEffect(() => {
    if (!searchable?.length || de_keyword_prev === de_keyword) {
      return;
    }

    let kwd = de_keyword?.trim();
    if (parser_keyword) {
      const parsed = parser_keyword(de_keyword, a);
      if (parsed) {
        set_a(parsed as any);
      } else {
        load_a_by_keyword(kwd as any);
      }
    } else {
      load_a_by_keyword(kwd);
    }
    on_keyword_change && on_keyword_change(kwd);
    list_reload();
  }, [
    a,
    de_keyword,
    de_keyword_prev,
    list_reload,
    load_a_by_keyword,
    on_keyword_change,
    parser_keyword,
    searchable?.length,
  ]);

  useEffect(() => {
    if (rows_merge && !isEqual(rows_merge, rows_merge_prev)) {
      let rows = rows_merge;
      if (!Array.isArray(rows)) {
        rows = [rows];
      }
      ls_upsert(rows);
    }
  }, [rows_merge, rows_merge_prev, ls_upsert]);

  useEffect(() => {
    if (rows_remove && !isEqual(rows_remove, rows_remove_prev)) {
      let rows = rows_remove;
      if (!Array.isArray(rows)) {
        rows = [rows];
      }
      ls_remove(rows);
      _count && set__count(_count - rows.length);
    }
  }, [_count, ls_remove, rows_remove, rows_remove_prev]);

  useEffect(() => {
    if (!bus) {
      return;
    }
    const sub = bus.subscribe(({ type, data }: T_lister_event<Row>) => {
      switch (type) {
        case N_list_event.append:
          ls_append(data);
          break;
        case N_list_event.prepend:
          ls_prepend(data);
          break;
        case N_list_event.cram:
          ls_cram(data);
          break;
        case N_list_event.merge:
          ls_upsert(data);
          break;
        case N_list_event.remove:
          ls_remove(data);
          break;
        case N_list_event.replace:
          ls_remove(data.map((it: any) => it[prop_key]));
          ls_upsert(data);
          break;
        case N_list_event.move:
          ls_move(data.from, data.to, data.pid);
          break;
        case N_list_event.move_by_key:
          ls_move_by_key(data.key, data.to);
          break;
      }
    });
    return () => sub.unsubscribe();
  }, [bus, ls_append, ls_cram, ls_move, ls_move_by_key, ls_prepend, ls_remove, ls_upsert, prop_key]);

  useEffect(() => {
    if (el_content.current) {
      el_scroller_y.current = scroll_parent(el_content.current);
    }
  }, []);

  useEffect(() => {
    el_scroller_y.current?.scrollTo({ left: 0, top: scroll_y });
  }, [ls, scroll_y]);

  useEffect(() => {
    if (ls.length && scroll_to && scroll_to !== last_scroll_id) {
      scroll_to_id(scroll_to);
    }
  }, [last_scroll_id, ls.length, scroll_to, scroll_to_id]);

  useEffect(() => {
    set__keyword(keyword);
  }, [keyword]);

  useEffect(() => {
    set__count(count);
  }, [count]);

  useEffect(() => {
    on_pending_change?.(p_page);
  }, [on_pending_change, p_page]);

  return (
    <div className="flex w-full flex-col gap-2 overflow-auto">
      {!hide_search && !!searchable?.length && (
        <div>
          <input
            className="w-full border-b bg-transparent p-2"
            onChange={_on_keyword_change}
            type="search"
            placeholder={search_placeholder ? search_placeholder : `Search: ${searchable.join(', ')}`}
            autoFocus={search_auto_focus}
          />
        </div>
      )}
      {render_header && render_header()}
      <div style={style_scroll}>
        {!list && !is_empty && action_bar_render(!reverse)}
        <div ref={el_content}>{render_list()}</div>
        {!list && !is_empty && action_bar_render(reverse)}
      </div>
      {render_footer && render_footer()}
    </div>
  );
};

// const Lister = <Row extends Base_pk = any, Args extends T_args = I_base_read>({
const Lister_memo = memo<I_Lister>(Lister) as typeof Lister;

export default Lister_memo;

export interface I_filter {
  pid_sensitive?: boolean;
}

export interface I_set_ls extends I_filter {}
