import _merge from 'lodash/merge';

export function list_upsert<T>(old: T[], patch: T[], key: keyof T, opt?: { merge?: boolean; prepend?: boolean }): T[] {
  const { merge, prepend } = opt || {};
  patch.forEach((p) => {
    const i = old.findIndex((o) => o[key] === p[key]);
    if (i === -1) {
      prepend ? old.unshift(p) : old.push(p);
    } else {
      old[i] = merge ? _merge({}, old[i], p) : p;
    }
  });

  return old;
}
